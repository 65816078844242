import React  from 'react';
import styles from './ToggleTranslation.module.css';

const ToggleTranslation = ( props ) => (
    <span className={styles.toggle}>Translation
        <label className={styles.switch}>
            <input type="checkbox" 
                onClick={props.onSwitch} 
                checked={props.show_translation}
                defaultChecked={true}/>
            <span className={styles.slider}></span>
        </label>
    </span>
); 

export default ToggleTranslation;