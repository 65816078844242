import React, { Component }  from 'react';
import styles from './Feedback.module.css';
import axios from 'axios'; 
import lfeedback from "../../data/feedback.json";

const feedback = lfeedback;

const GOOGLE_FORM_MESSAGE_ID = 'entry.1535880772'
const GOOGLE_FORM_ACTION = 'https://docs.google.com/forms/u/2/d/e/1FAIpQLSc8VK7t2WzhYC5xxG8epjm1RlGUB8t6EBnERlcFW9etwDBvyQ/formResponse'
const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'

class Feedback extends Component {
  constructor(props){
    super(props)
    this.state = { 
      message: '', 
      // email: '', 
      showForm: true,
      sendingMessage: false,
      messageSent: false,
      messageError: false,
      language: this.props.language,
    }
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      sendingMessage: true
    })
    this.sendMessage()
  }

  handleFormToggle = () => {
    this.setState( 
      (prevState) => {
        const { showForm } = prevState
        return {
          showForm: !showForm
        } 
      }
    )
  }

  handleReturnButton = () => {
    this.setState(
      {
        showForm: false,
        messageSent: true,
        messageError: false
      }
    )
  }

  sendMessage = () => {
    const formData = new FormData()
    formData.append(GOOGLE_FORM_MESSAGE_ID, this.state.message)
    // formData.append(GOOGLE_FORM_EMAIL_ID, this.state.email)

    axios.post(CORS_PROXY + GOOGLE_FORM_ACTION,formData)
      .then(() => {
        this.setState({ 
          messageSent: true,
          sendingMessage: false,
          message: ''//,
          // email: ''
        })
      }).catch(() => {
        this.setState({ 
          messageError: true,
          sendingMessage: false
        })
      })
  }

  returnButton = () => <button id='return-button' className={styles.submitButton} onClick={this.handleReturnButton}>Return</button>

  render() {
    if(this.state.sendingMessage){
      return(
        <div>Sending...</div>
      )
    }

    if(this.state.messageSent){
      return(
        <React.Fragment>
          <div className={styles.message}>Thank you for your feedback!</div>
          {/* {this.returnButton()} */}
        </React.Fragment>
      )
    }

    if(this.state.messageError){
      return(
        <React.Fragment>
          <div className={styles.message}>Sorry, your feedback failed to send. You can reach us at support@nulango.com</div>
          {this.returnButton()}
        </React.Fragment>
      )
    }

    if(this.state.showForm === false){
      return(
        <button id='contact-button' className={styles.submitButton} onClick={this.handleFormToggle}>Submit Feedback</button>
      )
    }

    return(
      <React.Fragment>
        <div className={styles.form_container}>
          <form onSubmit={this.handleSubmit}>
          {/* <div className='form-group row'>
              <label htmlFor='email' className='col-sm-2 col-form-label'>
                Email:
              </label>
              <div className='col-sm-8'>
                <input 
                  type='email' 
                  name='email'
                  id='email'
                  className='form-control'
                  value={this.state.email} 
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div> */}
            
            <div className={styles.form_group} key={this.props.language.lower}>
              <h2 className={styles.head}>{feedback[0][this.props.language.lower]}</h2>
              {/* <label htmlFor='message' className={styles.head}>
                Have feedback?
              </label> */}
              <div className={styles.text_div}>

                <textarea 
                  id='message'
                  name='message'
                  //added true
                  // placeholder="We'd love to hear it."
                  required={true}
                  value={this.state.message} 
                  onChange={this.handleChange} 
                  rows='6'
                  type="textarea"
                />
              </div>
            </div>
            <div>
              {/* <button type='button' id='cancel-button' className={styles.submitButton} onClick={this.handleFormToggle}>Cancel</button> */}
              <button type='submit' className={styles.submitButton}>{feedback[1][this.props.language.lower]}</button>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default Feedback;