import React from 'react';
import Aux from '../../hoc/Aux';
import NulangoMain from '../../containers/NulangoMain/NulangoMain';
// import Profile from '../../containers/Profile/Profile';
// import TopNavBar from '../../containers/TopNavBar/TopNavBar';
//import NavBar from '../../containers/NavBar/NavBar';
//import LeftSideBar from '../../containers/LeftSideBar/LeftSideBar';
import { Route } from 'react-router-dom';
// import classes from './Layout.css';
import styles from './Layout.module.css';

const Layout = ( props ) => (
<Aux className={styles.Layout}>
    {/* <TopNavBar className={styles.TopNav}/>*/}
    <Route className={styles.NulangoMain} path='/' exact component = { NulangoMain } />

    {/*<Route className={styles.FeedSection} path='/profile' exact component = {Profile} />{/*TODO: use /prop.name or something dynamic */}
</Aux>
);

export default Layout;