import React  from 'react';
import styles from './ToggleTransliteration.module.css';

const ToggleTransliteration = ( props ) => (
    <span className={styles.toggle}>Transliteration
        <label className={styles.switch}>
            <input type="checkbox" 
                onClick={props.onSwitch} 
                checked={props.show_transliteration}
                defaultChecked={false}/>
            <span className={styles.slider}></span>
        </label>
    </span>
); 
 
export default ToggleTransliteration;