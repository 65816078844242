import React from 'react';
import styles from './LanguageDropdown.module.css';
import arabic_flag from '../../assets/flags/arabic.svg'; 
import english_flag from '../../assets/flags/english.svg'; 
import french_flag from '../../assets/flags/french.svg'; 
import german_flag from '../../assets/flags/german.svg'; 
import hindi_flag from '../../assets/flags/hindi.svg'; 
import italian_flag from '../../assets/flags/italian.svg'; 
import japanese_flag from '../../assets/flags/japanese.svg'; 
import mandarin_flag from '../../assets/flags/mandarin.svg'; 
import portuguese_flag from '../../assets/flags/portuguese.svg'; 
import russian_flag from '../../assets/flags/russian.svg'; 
import spanish_flag from '../../assets/flags/spanish.svg'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const flags = {
  'arabic': arabic_flag,
  'english': english_flag,
  'french': french_flag,
  'german': german_flag,
  'hindi': hindi_flag,
  'italian': italian_flag,
  'japanese': japanese_flag,
  'mandarin': mandarin_flag,
  'portuguese': portuguese_flag,
  'russian': russian_flag,
  'spanish': spanish_flag,
}

const LanguageDropdown = (props) => (
    <div className={styles.dropdown}>
        <button className={styles.selectbtn}> 
          <img className={styles.flag} src={flags[props.selected.lower]} alt="flag"/>
          {props.selected[String(props.native_lang)] === '' ? <span className={styles.separator}></span> : ''} {props.selected.native}
        <FontAwesomeIcon 
          className={styles.icon}
          icon={["fas", "chevron-down"]}/>
        </button>
        <div className={styles.dropdown_content}>
          { props.languages.map((language, id) => (
            <h2><button
              key={id}
              className={props.selected.lower === language['lower'] ? styles.activeDropbtn: styles.dropbtn}
              onClick={() => props.onSelectLanguage(language, props.whichState)}>
                <img id={id} className={styles.flag} src={flags[language.lower]} alt="flag"/>
                
                &nbsp;&nbsp;{ language[String(props.native_lang)] } &nbsp;|&nbsp;  { language.native }
            
            </button></h2>
            
        ))}
      </div>
    </div>
);

export default LanguageDropdown;