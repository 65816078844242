import React  from 'react';
import styles from './AboutNulango.module.css';
import labout from "../../data/about.json";

const about = labout;

const AboutNulango = (props) => (
    <div className={styles.About} key={props.language.lower}>
      <h2 className={styles.header_text}>{about[0][props.language.lower]}</h2>
      <p className={styles.aboutText}>{about[1][props.language.lower]}</p>
      <p className={styles.contactUs} >{about[2][props.language.lower]} <a href="mailto:support@nulango.com">support@nulango.com</a></p>
    </div>
);

export default AboutNulango;