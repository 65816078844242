import React, { Component } from 'react';
import styles from './NulangoMain.module.css';
import AboutNulango from '../../components/AboutNulango/AboutNulango'; 
import TopNavBar from '../../containers/TopNavBar/TopNavBar';
import Footer from '../../containers/Footer/Footer';
import CategorySelector from '../../components/CategorySelector/CategorySelector';
import Feedback from '../../components/Feedback/Feedback';
import FeedItems from '../../components/FeedItems/FeedItems';
import ToggleTranslation from '../../components/FeedItems/Toggles/ToggleTranslation'; 
import ToggleTransliteration from '../../components/FeedItems/Toggles/ToggleTransliteration'; 
import Subscribe from '../../components/Subscribe/Subscribe'; 

// load local json files. l just stands for local. Eventually, we'll replace all of this
// api calls to our database.
//load initial data right away, the rest is dynamically imported. 
import lcategories from "../../data/categories.json";
// import ladjectives from "../../data/adjectives.json";
import lgreetings from "../../data/greetings.json";
import languages from "../../data/languages_config.json";

//put initial data into an array instead of json object. 
const data = {}
data['greetings'] = lgreetings;
data['categories'] = lcategories

// in future we will want to limit the data we load at first. 
// const load_interval = 50;

const all_languages = languages;

class NulangoMain extends Component {
    state = {
      ref_language: all_languages[1],
      comp_language: all_languages[10],
      show_translation: true,
      show_transliteration: false,
      langauges_available: all_languages,
      region: 'Spain',
      categories: data['categories'],
      category_filter: data['categories'][0],
      display_data: data['greetings'],
      show_lang_dropdown: false,
      progress: 0 
    };

    componentDidMount() {
        //let new_content = data[this.state.category_filter.file].slice(this.state.progress * load_interval, this.state.progress * load_interval + load_interval)
        let comparison_languages = all_languages.filter(all_languages => all_languages.lower !== this.state.ref_language.lower);
        this.setState(
          {
            langauges_available: comparison_languages
          });
    }
      
    filterData = (category_id ) => {
      // added dynamic import here to only load the json files when they're selected
      // ideally, we can preload things but this will at least help with the initial
      // load time.  
      import(`../../data/${data['categories'][category_id].file}.json`)
      .then(({default: load_category}) => {
        data[[data['categories'][category_id].file]] = load_category
        let new_data = data[[data['categories'][category_id].file]]
        this.setState({category_filter: data['categories'][category_id]})
        this.setState({display_data: new_data})
        })
    }
    
    refLanguageHandler = ( lang, stateToChange  ) => {
      this.setState(
        {[stateToChange]: lang},
        function() { 
        if (stateToChange === 'ref_language') {
          let language_list = all_languages.filter(all_languages => all_languages.lower !== lang.lower);
          this.setState({langauges_available: language_list})}
      })
    }
    
    categoryHandler = ( category_id ) => {
      let new_cat = data['categories'][category_id]
      this.setState(
        {
          category_filter: new_cat,
          show_lang_dropdown: !this.state.show_lang_dropdown    
        })
      
      this.filterData(category_id)
    }


    speechHandler = ( phrase_id, phrase, language ) => {
      const audioEl = document.getElementById('audio_file'.concat(phrase_id)) 
      audioEl.play()
    }

    showTranlationHandler = () => {
      const newState = !this.state.show_translation
      this.setState({show_translation: newState}
    )}

    showTransliterationHandler = () => {
      const newState = !this.state.show_transliteration
      this.setState({show_transliteration: newState}
    )}

  render() {
    return (
        <>
        <TopNavBar 
            onSelectLanguage={this.refLanguageHandler} 
            ref_language={this.state.ref_language}
            comp_language={this.state.comp_language}
            avail_languages={this.state.langauges_available}
            all_languages={all_languages}/>
        <div className={styles.mainMenu}></div>
        <div className={styles.mainlayout}>
        <div className={styles.CategorySelector}>
        <CategorySelector 
            onSelectCategory={this.categoryHandler}
            selectedCategory={this.state.category_filter}
            ref_language={this.state.ref_language}
            categorySelector_icon={this.state.category_filter.icon}
            show_dropdown = {this.state.show_lang_dropdown}/>
          </div>
        <div className={styles.FeedItems}>
          { this.state.display_data.map((phrase, id) => (
          <FeedItems
              key={id}
              phrase={phrase}
              ref_language={this.state.ref_language}
              comp_language={this.state.comp_language}
              category={this.state.category_filter.file}
              region={this.state.region}
              showTranslation={this.state.show_translation}
              showTransliteration={this.state.show_transliteration}
              onSpeech={this.speechHandler}/>
          ))}
          <div className={styles.FooterContainer2}>
          <Footer />
       </div>
        <div className={styles.rightsidebarwrapper}>
          <div><Subscribe className={styles.RightSideBar} language={this.state.ref_language}/></div>
          <div><Feedback language={this.state.ref_language}/></div>
          <div><AboutNulango language={this.state.ref_language}/></div>
          <div className={styles.FooterContainer1}>
          <Footer language={this.state.ref_language}/>
          </div>
        </div>
        </div>
        <div className={styles.rightsidebarwrapper2}>
          <div><Subscribe className={styles.SubscribeDesktop} language={this.state.ref_language}/></div>
          <div><Feedback className={styles.FeedbackDesktop} language={this.state.ref_language}/></div>
          <div><AboutNulango className={styles.AboutDesktop} language={this.state.ref_language}/></div>
        </div>
        <div className={styles.toggle}>
         <span><ToggleTranslation onSwitch={this.showTranlationHandler} language={this.state.ref_language}/></span>
         <span><ToggleTransliteration onSwitch={this.showTransliterationHandler} language={this.state.ref_language}/></span>
        </div>
    </div>
    </>
    );
  }
}

export default NulangoMain;