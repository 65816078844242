// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import regular font awesome icons here. Remember to add them to the libary below as well. 
// import {faBookmark as faBookmarkRegular, 
//         faStar as faStarRegular, 
//         faCompass, 
//         faUser, 
//         faBell,
//         faMoneyBillAlt,
//       } from '@fortawesome/free-regular-svg-icons';

// import bold font awesome icons here. Remember to add them to the libary below as well. 
import {
      faKey,
       faBars,
      //  faBookmark as faBookmarkBold,
      //  faStar as faStarBold,
       // feed item icons
       faVolumeUp, // speak phrase icon
       faCheckCircle, // completed icon
       faPlusCircle,
       faEllipsisV,
       faEllipsisH,
       // categories
       faUtensils,
       faBus,
       faFirstAid,
       faHamburger,
      //  faChevronCircleDown,
       faHandshake,
       faRunning,
       faBolt,
       faBriefcase,
       faDirections,
       faFireAlt,
       faComments,
       faQuoteRight,
       faShoppingBag,
       faHashtag,
       faClock,
       faPalette,
       faLeaf,
       faHome,
       faPiggyBank,
       faBookReader,
       faHammer,
       faLandmark,
       faEnvelope, 
       faPrayingHands,
       faHands,
       faQuestion,
       faTshirt,
       // nav bar icons
       faChevronDown,
       faPeopleArrows,
       faUserSecret,
       faCouch,
       faChartBar,
       faUsers
      } from '@fortawesome/free-solid-svg-icons';

library.add(
  //  faBookmark,
  faBars,
  // faBookmarkRegular,
  // faBookmarkBold,
  // faStarRegular,
  // faStarBold,
  // faStar,
  faKey,
  // faCompass,
  // faUser,
  // faBell,
  faPlusCircle,
  // nulango feed items
  faVolumeUp, // speak icon
  faCheckCircle, // complete
  faEllipsisV,
  faEllipsisH, // top more menu
  // nulango categories
  faFireAlt, // popular
  faUtensils, //dining
  faBus, //transport
  faFirstAid, // emergency
  faHamburger, // food
  // faMoneyBillAlt, // money
  // faChevronCircleDown, // more
  faHandshake, // greetings
  faRunning, // verbs
  faBolt, // everything else. 
  faBriefcase, // business
  faDirections, // directions
  faComments, // social
  faQuoteRight, //common phrases
  faShoppingBag, // shopping
  faHashtag, // numbers
  faClock, // time and date
  faPalette, //colors
  faLeaf, // nature
  faHome, // home
  faPiggyBank, // Banking
  faBookReader, // education
  faHammer, // work
  faEnvelope, // mail 
  faLandmark, // politics
  faPrayingHands, // religion and spirituality
  faHands,
  faChevronDown, // down for language selector
  faQuestion,
  faTshirt,
  faPeopleArrows,
  faUserSecret,
  faCouch,
  faChartBar,
  faUsers
);
