import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
return (
    <footer className={styles.copyrightFooter1}>
        <div className={styles.FooterContainer}>
            <p className={styles.Footer_copyrightFooter1}>Copyright &#169; Nulango 2020 - All Rights Reserved</p>
            <p className={styles.Footer_copyrightFooter2}>Copyright &#169; Nulango 2020 - All Rights Reserved</p>
            
        </div>
    </footer>
    )
}

export default Footer;