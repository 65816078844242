import React  from 'react';
import styles from './FeedItems.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeedItems = ( props ) => (
    <div className={styles.FeedItems}>
          {props.showTransliteration 
            ? <h2 className={styles.transliteration}> {props.phrase[props.comp_language.lower + 'pronunciation']} </h2>
            : (null)
          }
        <div className={styles.headLine}>
        { 'tts' in props.phrase && props.comp_language.lower in props.phrase.tts &&
          <div>
            <FontAwesomeIcon 
            className={styles.speakerIcon}
            icon={["fas", "volume-up"]}
            onClick={() => props.onSpeech(props.phrase.id, props.phrase[props.comp_language.lower], props.comp_language)}
            >
            </FontAwesomeIcon>
            <audio id={'audio_file'.concat(props.phrase.id)} key={props.phrase.tts[props.comp_language.lower][props.comp_language.regions[0].region].voices[0]} className="audio-element" preload="none">
                <source key={props.phrase.tts[props.comp_language.lower][props.comp_language.regions[0].region].voices[0]} 
                src={`https://s3.us-west-2.amazonaws.com/nulango-phrases-prod/${props.category}/${props.comp_language.lower}/${props.phrase.tts[props.comp_language.lower][props.comp_language.regions[0].region].voices[0]}`} type="audio/mpeg"
                onError={null}/>
                Your browser does not support the audio element.
            </audio>
          </div>
        }
        <p className={styles.phrase_title}>{props.phrase[props.comp_language.lower]}</p>
        </div>
        {props.showTranslation 
          ? <p className={styles.ref_phrase}>{props.phrase[props.ref_language.lower]}</p>
          : (null)
        }
        {/* <FontAwesomeIcon 
          className={styles.checkIcon}
          icon={["fas", "check-circle"]}/> */}
    </div>

);

export default FeedItems;
