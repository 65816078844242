import React  from 'react';
import styles from './Subscribe.module.css';
import Mailchimp from 'react-mailchimp-form';
import lsubscribe from "../../data/subscribe.json";

const subscribe = lsubscribe;
 
class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        emailValue: '',
        fNameValue: '',
        lNameValue: '',
        language: this.props.language,
        headline:subscribe[0][this.props.language.lower],
    };
  }

  componentDidMount() {
    //let new_content = data[this.state.category_filter.file].slice(this.state.progress * load_interval, this.state.progress * load_interval + load_interval)
    let text = subscribe[0][this.state.language.lower];
    this.setState({headline: text})
  };

  render() {
        return (
          
          <div className={styles.Subscribe} key={this.props.language.lower}>
          {/* <h4 className={styles.header_text}>Get language learning tips in your inbox, stay up to date with Nulango.</h4> */}
          <h4  className={styles.header_text}>{subscribe[0][this.props.language.lower]}</h4>
          <Mailchimp
          action='https://nulango.us8.list-manage.com/subscribe/post?u=195c7a705040ac33dda1b369a&amp;id=6e7aa2a54d'
          fields={[
            {
              name: 'EMAIL',
              placeholder: subscribe[2][this.props.language.lower],
              type: 'email',
              required: true
            }
          ]}
          messages = {
            {
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "An unexpected internal error has occurred.",
              empty: "You must write an e-mail.",
              duplicate: "Too many subscribe attempts for this email address",
              button: subscribe[1][this.props.language.lower]
            }      
          }
          />
        </div>
        )
    }
}

export default Subscribe