import React from 'react';
import styles from './CategorySelector.module.css';
import lcategories from '../../data/categories.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const categories = lcategories; 

const CategorySelector = ( props ) => (
    <div className={styles.CategorySection}>
      <button className={styles.CategoryButton}>
           <h3> <FontAwesomeIcon 
              className={styles.mobile_icon}
              style={{ color: props.selectedCategory.color, opacity: '1'}}
              icon={props.categorySelector_icon}
              fixedWidth
              /> 
              {props.selectedCategory[props.ref_language.lower]}
          <FontAwesomeIcon className={styles.dropdown_icon} icon={["fas", "chevron-down"]}/></h3>
      </button>
      <div className={styles.dropdown}>
      {/* style={props.show_dropdown ? {display: 'block'} : {display: 'none'}} */}
        { categories.map((category, id) => (
        <div 
          key={id}
           className={styles.lang_btn}
          style={props.selectedCategory.english === category.english ? {opacity:'1'} : {opacity:'.7'}}
          onClick={() => {props.onSelectCategory(category.id)}}> 
          <FontAwesomeIcon 
            className={styles.icon}
            style={{ color: category.color }}
            icon={category.icon}
            fixedWidth /> 
          <button 
            className={styles.label}
            >
            {category[props.ref_language.lower]}
          </button>
        </div>
        ))}
        </div>
      </div>
)

export default CategorySelector;
