import React from 'react';
import '../../App.css';
import styles from './TopNavBar.module.css';
import Logo from '../../assets/worldwide.svg';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';

const TopNavBar = ( props ) => (
      <div className={styles.TopNavBar}>
        <img className={styles.Logo} src={Logo} width='100px' height='100px' alt='Learn a language faster'/>
        <title className={styles.header}>Nulango</title>
        <LanguageDropdown languages={props.all_languages} selected={props.ref_language} native_lang={props.ref_language.lower} {...props} whichState='ref_language'/>
        <LanguageDropdown languages={props.avail_languages} selected={props.comp_language} native_lang={props.ref_language.lower} {...props} whichState='comp_language'/>
      <h1 className={styles.subtitle}>Quickly learn a language</h1>
    </div>
    );
   
    
export default TopNavBar;